<template>
    <section class="description-block">
        <div v-if="info.title" class="content_head">
            <h3 class="heading">
                <span>{{ title }}</span>
            </h3>
        </div>
        <div class="description" v-html="content"></div>
    </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useDateFunctions } from '~/composables/DateFunctions'
import { replacePlaceholders } from '~/util/textFunction'

export default defineComponent({
    name: 'SidebarDescription',
    props: {
        info: {
            type: Object as PropType<{ title: string | null; content: string | null }>,
            required: true,
        },
        storeName: {
            type: String,
        },
    },
    data() {
        const { currentDate } = useDateFunctions()
        const currentDates = currentDate()
        const [title, content] = replacePlaceholders(
            [
                ...(this.storeName ? [[':store', this.storeName || ''] as [string, string]] : []),
                [':month', currentDates.month || ''],
                [':year', String(currentDates.year) || ''],
                [':day', String(currentDates.day) || ''],
                [':site', this.$config.public.siteName || ''],
            ],
            [this.info.title || '', this.info.content || ''],
        )
        return {
            title,
            content,
        }
    },
})
</script>

<style lang="postcss" scoped>
.description-block {
    @apply rounded-2xl border border-opacity-5 bg-white px-8 py-6;
    .content_head {
        @apply mb-2 flex justify-between;
        .heading {
            @apply text-lg font-semibold;
        }
    }
    .description {
        @apply space-y-3 self-center text-base text-gray-800;
    }
    .no-similar {
        @apply py-4 text-center text-site-gray;
    }
}
</style>
